<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters class="px-5 mb-5 pt-2 pb-3">
        <v-col cols="12" lg="2" sm="12" class="mr-5">
          <v-select
            class="select"
            v-model="state"
            :items="search_items"
            label="상태"
            item-text="text"
            item-value="value"
            hide-details
            @change="searchItem()"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" sm="12">
          <v-text-field
            v-model="search"
            @keyup.enter="searchItem()"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="1"
          sm="12"
          class="mt-2"
          style="white-space: nowrap"
        >
          <v-btn color="primary" @click="searchItem()" class="mr-2">검색</v-btn>
          <v-btn @click="refresh()">초기화</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mb-16">
      <v-col>
        <v-simple-table class="table">
          <template>
            <thead class="sticky_header">
              <tr>
                <th class="text-center">상태</th>
                <th class="text-center">회사명</th>
                <th class="text-center">담당자</th>
                <th class="text-center">이메일</th>
                <th class="text-center">연락처</th>
                <th class="text-center">등록일</th>
                <th class="text-center">갱신일</th>
                <th class="text-center">접속 경로</th>
                <th class="text-center">상담 등록</th>
              </tr>
            </thead>
            <tbody v-for="(item, i) in items" :key="i">
              <tr>
                <td style="width: 150px">
                  <v-select
                    v-model="item.state"
                    :items="state_items"
                    item-text="text"
                    item-value="value"
                    attach
                    chips
                    hide-details
                    class="ma-0 pa-0"
                    @change="changeState(item.id, item.state)"
                  >
                    <template v-slot:selection="data">
                      <v-chip :style="{ background: getColor(data.item.text) }">
                        <span class="badge">{{ data.item.text }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </td>
                <td>{{ item.company }}</td>
                <td>{{ item.author }}</td>
                <td>{{ item.from }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.updated_at }}</td>
                <td>
                  {{ item.referer === "direct" ? "다이렉트" : item.referer }}
                </td>
                <td>
                  <v-btn color="primary" small @click="editItem(item)"
                    >등록</v-btn
                  >
                </td>
              </tr>
              <tr>
                <td colspan="9" class="text-left">
                  <v-text-field
                    v-model="item.goal"
                    label="사용목적"
                    outlined
                    readonly
                    hide-details
                    style="font-size: 14px"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  class="text-left py-3"
                  style="border-bottom: 2px solid rgba(0, 0, 0, 1) !important"
                >
                  <v-textarea
                    v-model="item.body"
                    label="문의 내용"
                    outlined
                    readonly
                    hide-details
                    style="font-size: 14px"
                  ></v-textarea>
                </td>
                <td
                  colspan="4"
                  class="text-left py-3"
                  style="border-bottom: 2px solid rgba(0, 0, 0, 1) !important"
                >
                  <v-textarea
                    v-model="item.memo"
                    label="상담 내용"
                    outlined
                    readonly
                    hide-details
                    style="font-size: 14px"
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          color="primary"
          class="float-right mt-2"
          @input="getData()"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="700px"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>상담 등록</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-subheader>상세 정보</v-subheader>
        <v-container class="pt-0">
          <v-row>
            <v-col>
              <v-text-field
                v-model="editedItem.company"
                label="회사명"
                readonly
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="editedItem.author"
                label="담당자"
                readonly
                hide-details
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="editedItem.from"
                label="이메일"
                readonly
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="editedItem.phone"
                label="연락처"
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="editedItem.body"
                label="문의 내용"
                readonly
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-subheader>상태</v-subheader>
        <v-container class="pt-0">
          <v-row no-gutters>
            <v-text-field
              v-model="editedItem.state_kor"
              label="상태"
              readonly
              hide-details
            ></v-text-field>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-subheader>상담 내용</v-subheader>
        <v-container class="pt-0">
          <v-row no-gutters>
            <v-textarea
              ref="memo"
              v-model="new_memo"
              label="상담 내용"
            ></v-textarea>
          </v-row>
          <v-row no-gutters>
            <v-textarea
              v-model="editedItem.memo"
              label="상담 히스토리"
              readonly
              hide-details
            ></v-textarea>
          </v-row>
        </v-container>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="!valid"
            color="blue darken-1 white--text"
            @click="save(editedItem.id, new_memo)"
            >등록
          </v-btn>
          <v-btn color="primary white--text" @click="dialog = false"
            >닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ScrollTop />
  </v-container>
</template>

<script>
import ScrollTop from "@/components/common/ScrollTop.vue";

export default {
  name: "InquiryManage",
  components: {
    ScrollTop,
  },
  data() {
    return {
      state: "",
      search_items: [
        {
          text: "전체",
          value: "",
        },
        {
          text: "접수",
          value: "pending",
        },
        {
          text: "확인",
          value: "checked",
        },
        {
          text: "진행",
          value: "processing",
        },
        {
          text: "종료",
          value: "handled",
        },
        {
          text: "완료",
          value: "completed",
        },
      ],
      state_items: [
        {
          text: "접수",
          value: "pending",
        },
        {
          text: "확인",
          value: "checked",
        },
        {
          text: "진행",
          value: "processing",
        },
        {
          text: "종료",
          value: "handled",
        },
        {
          text: "완료",
          value: "completed",
        },
      ],
      items: [],
      loading: false,

      new_memo: "",
      editedItem: {},

      page: 1,
      per_page: 10,
      pageCount: 1,
      search: "",

      dialog: false,
    };
  },
  computed: {
    valid() {
      if (this.new_memo) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    page() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    changeState(id, state) {
      let data = {
        state: state,
      };
      this.axios.patch(`api/v1/contact/mail/${id}`, data).then(() => {
        this.getData();
        this.$store.commit("alert/showAlert", {
          message: `상태가 변경되었습니다.`,
        });
      });
    },

    getColor(state) {
      if (state === "접수") {
        return "#EB5353";
      } else if (state === "확인") {
        return "#F9D923";
      } else if (state === "진행") {
        return "#36AE7C";
      } else if (state === "종료") {
        return "black";
      } else if (state === "완료") {
        return "#B2B1B9";
      }
    },

    searchItem() {
      this.page = 1;
      this.getData();
    },

    refresh() {
      this.state = "";
      this.search = "";
      this.searchItem();
    },

    getData() {
      this.loading = true;
      let items = [];
      let params = {
        page: this.page,
        per: this.per_page,
        search: this.search,
        state: this.state,
      };
      this.axios
        .get(`api/v1/contact/mail`, { params })
        .then((res) => {
          if (res.data.list.data.length > 0) {
            res.data.list.data.map((el) => {
              items.push({
                id: el.id,
                company: el.content.company,
                author: el.content.author,
                from: el.content.from,
                phone: el.content.phone,
                body: el.content.body,
                goal: el.content.goal,
                memo: el.memo,
                state_kor: el.state.kor,
                state: el.state.state.substr(1),
                created_at: el.created_at,
                updated_at: el.updated_at,
                referer: el.referer,
              });
            });
          }

          this.items = items;

          if (Number.isInteger(res.data.list.total / this.per_page) === false) {
            this.pageCount =
              Math.floor(res.data.list.total / this.per_page) + 1;
          } else {
            this.pageCount = res.data.list.total / this.per_page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    setFocus() {
      setTimeout(() => {
        this.$refs.memo.focus();
      }, 50);
    },

    editItem(item) {
      this.new_memo = "";
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.setFocus();
    },

    close() {
      this.dialog = false;
    },

    save(id, memo) {
      let data = {
        memo: memo,
      };
      this.axios.patch(`api/v1/contact/mail/${id}/memo`, data).then(() => {
        this.getData();
        this.$store.commit("alert/showAlert", {
          message: `상담 내용이 등록되었습니다.`,
        });
      });
      this.close();
    },
  },
};
</script>
<style scoped>
.table tbody tr > td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 0 !important;
}
.table tbody:hover {
  background: #eee;
}
.badge {
  color: #ffffff;
  font-weight: 600;
}
.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}
.v-select--chips >>> .v-input__slot::before,
.v-select--chips >>> .v-input__slot::after {
  display: none !important;
}
@media all and (max-width: 1023px) {
  .v-data-table >>> .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
</style>
